import { amount_icon, easypaisa_logo, phone_icon, ub_wallet_logo, bank_logo, img_search } from "../../../assets/images";

export const data = {
    easypaisa: [
        {
            stepTitle: "Open EasyPaisa app",
            link: 'youtube link here',
            stepIcon: easypaisa_logo,
            stepNo: "Step 1",
        },
        {
            stepTitle: "Search Udhaar book",
            stepIcon: ub_wallet_logo,
            stepNo: "Step 2",
        },
        {
            stepTitle: "Enter your Consumer ID",
            stepIcon: phone_icon,
            stepNo: "Step 3",
            copy: true
        },
        {
            stepTitle: "Enter amount and pay",
            stepIcon: amount_icon,
            stepNo: "Step 4",
        }
    ],
    bank: [
        {
            stepTitle: "Open bank app",
            stepIcon: bank_logo,
            stepNo: "Step 1",
        },
        {
            stepTitle: "Search for 1 Bill",
            link: 'youtube link here',
            stepIcon: img_search,
            stepNo: "Step 2",
        },
        {
            stepTitle: "Consumer ID",
            stepIcon: phone_icon,
            stepNo: "Step 3",
            copy: true
        },
        {
            stepTitle: "Enter amount and pay",
            stepIcon: amount_icon,
            stepNo: "Step 4",
        }
    ],
}

