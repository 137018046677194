import React from 'react';

const AppRadioButton = ({ customValue, ...rest }) => {
    return (
        <label className="appCheckBoxRow">
            <input
                className="option-input radio"
                type="radio"
                {...rest}
            />
            {customValue}
        </label>
    );
};

export default AppRadioButton;  