import React from 'react';
import { Modal } from 'reactstrap';

const MPin = ({ location, modal, history, number, payable, business_name, unique_code, error, toggle, setError, seconds, setSeconds, DURATION, setStartTimer, handleNext }) => {

    const confirmPayment = () => {
        history.push({
            pathname: '/success_screen',
            payable,
            number,
            business_name,
            unique_code,
        })
    }

    const goBack = () => {
        setStartTimer(false);
        setError('')
        setSeconds(DURATION);
        toggle();
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} backdrop='static' className="mPinModal" centered>
                {error == '' ? (
                    <div className="mPinText">
                        <p>Waiting for MPIN confirmation</p>
                        {seconds == 0 ? (
                            <div className="didnotRecieveCode">
                                <p>Did not recieve MPIN?</p>
                                <p className="tryAgainButton" onClick={() => handleNext()} >
                                    Try again
                                </p>
                            </div>
                        ) : (
                                <div className="waitingforCode">
                                    <p>{`Waiting for MPIN code: ${Math.floor(seconds / 60) == 0 ? '00' : '0' + Math.floor(seconds / 60)}:${(seconds - (Math.floor(seconds / 60)) * 60) < 10 ? '0' + (seconds - (Math.floor(seconds / 60)) * 60) : (seconds - (Math.floor(seconds / 60)) * 60) }`}</p>
                                </div>
                            )}
                    </div>
                ) : (
                        <div
                            onClick={goBack}
                        >
                            <p>{error}</p>
                            <div className="didnotRecieveCode">
                                <p className="tryAgainButton">Go Back</p>
                            </div>
                        </div>
                    )}
            </Modal>
        </>
    )
}

export default MPin;