import { AmountCard, AppFooter, PayMethods, PrimaryButton } from "../../common";
import { getConsumerID, getPaymentDetails } from "../../../SyncServices";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { path } from "../../../constants";
import { toast } from "react-toastify";

const HomeV2 = ({ history }) => {
  const [toInstalled, setToInstalled] = useState(false);
  const [payMethod, setPayMethod] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [laod, setLoad] = useState({
    payLaod: false,
    loading: true,
  });
  const [payDetails, setPayDetails] = useState({
    phone_number: "",
    store_name: "",
    amount: 0,
  });

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    if (navigator.onLine) {
      try {
        let params = {
          type: path[1] == "i" ? "invoice" : "salebook",
          business_report_url: path[2],
          invoice_id: path[3],
        };

        getPaymentDetails(params)
          .then((res) => {
            const { phone_number, store_name, amount, status, bill_type } =
              res || {};
            if (status == "paid" || bill_type == "out") {
              logEvent(
                analytics,
                `payment_link_receipt_${status == "paid" ? "paid" : "bill"}`,
                {}
              );
              history.replace(`/${path[1]}/${path[2]}/${path[3]}/receipt`, {
                ...res,
              });
            } else setPayDetails({ phone_number, store_name, amount });
          })
          .catch((err) => {
            if (err.response.status == 404) setInvalid(true);
          })
          .finally(() => setLoad({ ...laod, loading: false }));
      } catch (e) {
        setLoad({ ...laod, loading: false });
      }
    } else toast.error("Internet connection is required");
  };

  const handleReceipt = () => {
    logEvent(analytics, "payment_link_receipt", {});
    history.push(`/${path[1]}/${path[2]}/${path[3]}/receipt`);
  };

  const handlePayMethod = (event) => {
    logEvent(analytics, `payment_link_method_${event.target.value}`, {});
    if (toInstalled) setToInstalled(false);
    setPayMethod(event.target.value);
  };

  const handlePayNow = (event) => {
    logEvent(analytics, `payment_link_method_selected_paynow_cta`, {});
    event.preventDefault();

    if (payMethod == "easypaisa" || payMethod == "bank") {
      if (navigator.onLine) {
        setLoad({ ...laod, payLaod: true });

        let params = {
          partner: payMethod == "bank" ? "1bill" : "easypaisa",
          business_report_url: path[2],
          invoice_id: path[3],
        };

        getConsumerID(params)
          .then((res) => {
            const { consumer_number, banks } = res || {};
            history.push({
              pathname: `/${path[1]}/${path[2]}/${path[3]}/paynow`,
              consumer_number,
              payMethod,
              banks,
            });
          })
          .finally(() => setLoad({ ...laod, payLaod: false }));
      } else toast.error("Internet connection is required");
    } else {
      window.location.href = `udhaar://send/${path[1]}/${path[2]}/${path[3]}`;
      logEvent(analytics, `payment_link_method_ub_app_open`, {});

      setTimeout(() => {
        setToInstalled(true);
      }, 2000);
    }
  };

  return laod.loading ? (
    <div className="LoadingScreen">
      <Spinner animation="border" className="loaderCircle" />
    </div>
  ) : invalid ? (
    <section className="invoiceNotFound">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="paymentAmountCardInner">
              <p className="fontSize32 fontWeight500 darkOrangeColor marTop10 marBot10">
                Invoice not found
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <AppFooter />
    </section>
  ) : (
    <div className="">
      <AmountCard
        data={{
          tel: `tel: +92${payDetails.phone_number.slice(1, 11)}`,
          title: payDetails.store_name,
          amount: payDetails.amount,
          handleReceipt,
        }}
      />

      <section className="homeBodyBox">
        <Container>
          <Row>
            <Col>
              <h3 className="fontSize18 fontWeight700 darkGrayColor marBot20">
                Aap kaisey pay karenge?
              </h3>
              <form onSubmit={handlePayNow}>
                <PayMethods
                  payMethod={payMethod}
                  handlePayMethod={handlePayMethod}
                />

                {toInstalled ? (
                  <p className="statementPara">
                    Udhaar app download karne ke liye
                    <a href="https://play.google.com/store/apps/details?id=com.oscarudhaarapp&hl=en&gl=US">
                      Click karein
                    </a>
                  </p>
                ) : null}

                <div className="homeActionBox">
                  <PrimaryButton
                    data={{
                      className: `width40 fontSize16 fontWeight600 textUppercase buttonStyle ${
                        payMethod && payDetails.amount
                          ? "BtnGreenBg whiteColor"
                          : "BtnwhiteBg lightGrayColor BtnDisable"
                      }`,
                      disabled: Boolean(!payMethod || laod.payLaod),
                      loading: laod.payLaod,
                      title: "pay Now",
                      type: "submit",
                    }}
                  />
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
      <AppFooter />
    </div>
  );
};

export default HomeV2;
