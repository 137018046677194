import React, { useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../../../constants";
import "./style.css";
import logo from "../../../assets/images/logo.png";
import headerBanner from "../../../assets/images/headerBanner.png";
import {
  LanguageContext,
  LanguageProvider,
} from "../../../context/LanguageContext";
import ur from "../../../languageConfig/local/ur";
import en from "../../../languageConfig/local/en";

const Header = ({ business_name, cashbackPromotion }) => {
  const [I18n, setLanguage] = useContext(LanguageContext);
  useEffect(() => {
    const res = localStorage.getItem("language");

    if (res) {
      changeLag(res);
    }
  }, []);

  const changeLag = (lang) => {
    if (lang == "ur") {
      setLanguage(ur);
    } else if (lang == "en") {
      setLanguage(en);
    }

    localStorage.setItem("language", lang);
  };
  // alert(cashbackPromotion);
  return (
    <header
      className={
        cashbackPromotion
          ? "headerNewBanner"
          : "headerNewBanner headerwithoutBanner"
      }
    >
      {/* <Container fluid={true}> */}
      <Container>
        <Row>
          <Col sm={12} md={{ size: 11, offset: 1 }}>
            <div className="langaugeButtonBox">
              <button
                onClick={() => {
                  changeLag("en");
                }}
              >
                <span>
                  <i
                    className={
                      I18n.languageType === "ur"
                        ? "englishIcon"
                        : "englishIconActive"
                    }
                  ></i>
                </span>
              </button>
              <p className="seperator"></p>
              <button
                onClick={() => {
                  changeLag("ur");
                }}
              >
                <span>
                  <i
                    className={
                      I18n.languageType == "ur" ? "urduIconActive" : "urduIcon"
                    }
                  ></i>
                </span>
              </button>
            </div>
          </Col>
          {/* <Col md="6" lg="6" sm="6" xs="6">
                        <div className="headerLogo">
                            <img src={logo} />
                            <p>
                                Aapka business partner
                            </p>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="6">
                        <div className="headerRight">
                            
                            <p className="gpIconMain">
                                <span>
                                    Download Now
                                </span>
                                <a target='_blank' href={GOOGLE_PLAY_LINK}>
                                    <i className="gpIcon"></i>
                                </a>
                                <a target='_blank' href={APP_STORE_LINK}>
                                    <i className="appleIcon"></i>
                                </a>
                            </p>
                        </div>
                    </Col> */}
        </Row>
      </Container>
    </header>
  );
};

export default Header;
