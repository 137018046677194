import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  formatAMPM,
  formateDate_dd_month_yy,
  formatNum,
  getBackupDate,
} from "../../../constants";
import DownloadBanner from "../../common/DownloadBanner";
import Header from "../../common/Header";
import "./style.css";

const SuccessScreen = ({
  business_name,
  customer_name,
  refrenceId,
  cashbackPercentage,
  cashbackPromotion,
  accountNumber,
  unique_code,
  payable,
}) => {
  //   const date_time = getBackupDate(new Date());

  // useEffect(() => {
  //     if (unique_code === undefined) {
  //         const u_code = localStorage.getItem('unique_code')
  //         history.replace('/' + u_code);
  //     }
  // })

  return (
    <>
      {/* <section className="BodyContent"> */}
      <div className="BodyContentTop paymentFieldSection">
        <div className="paymentMethodHeading successHeading">
          <h3>Payment successful</h3>
          <p className="successHeadingTime">
            <span>{formateDate_dd_month_yy(new Date())}</span>
            <span>{formatAMPM(new Date())}</span>
          </p>
        </div>
        <div className="BodyContentTopInner successScreenBody">
          <p className="successScreenList">
            <span className="successScreenListLeft">From</span>
            <span className="successScreenListRight">{customer_name}</span>
          </p>
          <p className="successScreenList">
            <span className="successScreenListLeft">To</span>
            <span className="successScreenListRight">{business_name}</span>
          </p>
          <p className="successScreenList borderBottomNo">
            <span className="successScreenListLeft">Transaction ID</span>
            <span className="successScreenListRight">{refrenceId}</span>
          </p>
          <p className="circleBox">
            <span className="leftCircle"></span>
            <span className="rightCircle"></span>
          </p>
          <p className="succesScreenAmount">
            <span>Paid</span>
            <span>Rs. {formatNum(payable)}</span>
          </p>
          {cashbackPromotion ? (
            <p className="cashBackRow">
              <span className="cashBackRowLeft">
                Udhaar Book Cashback {formatNum(Math.round(cashbackPercentage))}
                %
              </span>
              <span className="cashBackRowRight">
                Rs.{formatNum(Math.abs((payable / 100) * cashbackPercentage))}
              </span>
            </p>
          ) : null}
          {/* <p className="succesScreenPhonenum">
                    <span>Phone #:</span>
                    <span>{accountNumber}</span>
                  </p> */}
          {/* <p className="succesScreentick">
            <i className="SuccestickIcon"></i>
          </p> */}
          {/* <p className="succesScreentime">{"Date: " + date_time}</p> */}

          {/* <p className="succesScreenbottomMessage">
            <span>
              <i className="smsIcon"></i>
            </span>
            <span>
              SMS Receipt has been sent to <b>{accountNumber}</b>
            </span>
          </p> */}
        </div>
      </div>
      {/* </section> */}
    </>
  );
};

export default SuccessScreen;
