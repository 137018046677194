import mpinImageJC from "../../../assets/images/mpinImageJC.png";
import { getPaymentInformation } from "../../../SyncServices";
import mPinImage from "../../../assets/images/mpinImage.png";
import React, { useEffect, useState, useContext } from "react";
import { logEvent } from "@firebase/analytics";
import { analytics } from "../../../firebase";
import { Spinner } from "reactstrap";
import { LanguageContext } from "../../../context/LanguageContext";

const DURATION = "120";

const MpinScreen = ({
  toggleMpinScreen,
  setrefrenceId,
  setMpinSend,
  unique_code,
  mpinSend,
  method,
}) => {
  const [I18n, setLanguage] = useContext(LanguageContext);
  const [seconds, setSeconds] = useState(DURATION);
  const [timeout, setTimeout] = useState(false);

  useEffect(() => {
    if (mpinSend) {
      handleVerifyMPIN();
    }
  }, []);

  useEffect(() => {
    if (mpinSend) {
      let s = seconds;

      const interval = setInterval(() => {
        s--;

        if (s === 0) {
          setTimeout(true);
          setSeconds(Number(DURATION));
          clearInterval(interval);
          setMpinSend(false);
          return;
        }

        if (s < 10) {
          s = "0" + s;
        }

        setSeconds(s);
      }, 1000);
    }
  }, [mpinSend]);

  const handleVerifyMPIN = () => {
    try {
      if (navigator.onLine) {
        let intr = setInterval(async () => {
          getPaymentInformation(unique_code)
            .then((res) => {
              const { payment_status, message, reference_id } = res;

              if (payment_status === "paid") {
                clearInterval(intr);
                setrefrenceId(reference_id);
                toggleMpinScreen(true);
                logEvent(analytics, "payment_successful", {
                  method,
                });
              } else if (payment_status == "failed") {
                toggleMpinScreen();
                clearInterval(intr);
                alert(message);
              }
            })
            .catch((err) => {
              toggleMpinScreen();
              alert("Unexpected error occured");
              clearInterval(intr);
            });
        }, 5000);
      }
    } catch (e) {}
  };

  const handleCloseScreen = () => {
    if (timeout) toggleMpinScreen();
    else return;
  };

  return (
    <div className="BodyContentTop paymentFieldSection mpinScreen">
      <div className={timeout ? "backIconMain" : "backIconMain CloseDisable"}>
        <span onClick={handleCloseScreen}>
          <i className="backIcon"></i>
        </span>
      </div>
      <div className="paymetClientMainHeading MpinHeading">
        <h3>{I18n.waiting_for_mpin_confirmation}</h3>
      </div>
      <div className="mPinText">
        {/* <div className="mpinScreenImage"></div> */}
        <img
          className="mpinScreenImage"
          src={method == "EasyPaisa" ? mPinImage : mpinImageJC}
          title="mPin Image"
          alt="mPin Imag"
        />
        {I18n.languageType == "ur" ? (
          <p className="easyPaisaAppopen">
            {I18n.app_kholein_aur_pin_enter_karein}{" "}
            {method == "JazzCash" ? I18n.JazzCash : I18n.EasyPaisa} {I18n.apna}
          </p>
        ) : (
          <p className="easyPaisaAppopen">
            {I18n.apna} {method == "JazzCash" ? I18n.JazzCash : I18n.EasyPaisa}{" "}
            {I18n.app_kholein_aur_pin_enter_karein}{" "}
          </p>
        )}
        {timeout ? (
          <div className="didnotRecieveCode">
            <p>Did not recieve MPIN?.</p>
            <p style={{ marginLeft: 2 }}>{I18n.please_try_again}</p>
          </div>
        ) : (
          <>
            <div className="waitingforCode mpinWaitingforCode">
              {I18n.languageType == "ur" ? (
                <p>
                  <span className="mpinwaitingText">
                    {Math.floor(seconds / 60) == 0
                      ? "00"
                      : "0" + Math.floor(seconds / 60)}
                    :
                    {seconds - Math.floor(seconds / 60) * 60 < 10
                      ? "0" + (seconds - Math.floor(seconds / 60) * 60)
                      : seconds - Math.floor(seconds / 60) * 60}{" "}
                    :
                  </span>
                  <span className="mpinwaitingText">
                    {I18n.app_needs_to_verify_your_easyload_amount}
                  </span>

                  <span className="mpinwaitingText">
                    {method == "JazzCash" ? I18n.JazzCash : I18n.EasyPaisa}
                  </span>
                  {/* {`${
                  method == "JazzCash" ? I18n.JazzCash : I18n.EasyPaisa
                } ${" "} ${I18n.app_needs_to_verify_your_easyload_amount} : ${
                  Math.floor(seconds / 60) == 0
                    ? "00"
                    : "0" + Math.floor(seconds / 60)
                }:${
                  seconds - Math.floor(seconds / 60) * 60 < 10
                    ? "0" + (seconds - Math.floor(seconds / 60) * 60)
                    : seconds - Math.floor(seconds / 60) * 60
                }
                
                `} */}
                </p>
              ) : (
                <p>{`${method == "JazzCash" ? I18n.JazzCash : I18n.EasyPaisa} ${
                  I18n.app_needs_to_verify_your_easyload_amount
                } : ${
                  Math.floor(seconds / 60) == 0
                    ? "00"
                    : "0" + Math.floor(seconds / 60)
                }:${
                  seconds - Math.floor(seconds / 60) * 60 < 10
                    ? "0" + (seconds - Math.floor(seconds / 60) * 60)
                    : seconds - Math.floor(seconds / 60) * 60
                }`}</p>
              )}
            </div>
            <div className="waitingLoad">
              <Spinner animation="border" className="loaderCircle" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MpinScreen;
