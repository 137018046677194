import EasyPaisa from "../../../assets/images/easyPaisaIcon.png";
import EasyPaisaFav from "../../../assets/images/EP.png";
import BTransfer from "../../../assets/images/bankIcon.png";
import JazzCash from "../../../assets/images/JC.png";
import BAFL from "../../../assets/images/BAFL.png";
import React, { useContext, useEffect, useRef } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import PaymentForm from "../PaymentForm";
import { Spinner } from "reactstrap";
import "./style.css";
import {
  LanguageContext,
  LanguageProvider,
} from "../../../context/LanguageContext";

const PaymentMethod = ({
  cashbackPercentage,
  cashbackPromotion,
  setAccountNumber,
  otpRequestLoad,
  accountNumber,
  alfalahStatus,
  business_name,
  unique_code,
  handleNext,
  setMethod,
  setErrors,
  history,
  alfalah,
  method,
  errors,
}) => {
  const amountRef = useRef();
  const [I18n, setLanguage] = useContext(LanguageContext);
  useEffect(() => {
    logEvent(analytics, "payment_method_viewed");
    if (unique_code == undefined) {
      const u_code = localStorage.getItem("unique_code");
      history.replace("/" + u_code);
    }
  }, []);

  const handleMethod = (value) => {
    logEvent(analytics, "payment_method_selected", {
      value,
    });
    setErrors({ ...errors, method: "" });
    setMethod(value);
    amountRef?.current?.focus();
  };

  const handleChange = (text) => {
    setErrors({ ...errors, accountNumber: "" });

    let newText = "";
    for (var i = 0; i < text.length; i++) {
      if (!isNaN(text[i]) && text[i] !== " ") {
        newText = newText + text[i];
        if (newText.length == 11) logEvent(analytics, "account_number_entered");
      }
    }
    setAccountNumber(newText);
  };

  return (
    <>
      <div className="BodyContentTop paymentFieldSection">
        <div
          className={
            I18n.languageType == "ur"
              ? "paymentMethodNewCont paymentMethodNewContUrdu"
              : "paymentMethodNewCont"
          }
        >
          <div
            className={
              I18n.languageType == "ur"
                ? "paymentMethodHeading paymentMethodHeadingUrdu"
                : "paymentMethodHeading"
            }
          >
            {method === "Alfalah" ? (
              <h3>{I18n.select_payment_method}, or card</h3>
            ) : (
              <h3>{I18n.select_payment_method}</h3>
            )}
          </div>

          {/* <div style={{ display: "flex" }}>
            {method === "Alfalah" ? (
              <h6 className="paymentMethodSubHeading">Wallet or Bank</h6>
            ) : (
              <h6 className="paymentMethodSubHeading">Wallet</h6>
            )}
            {errors.method ? <h6 style={{ fontSize: 13, color: '#e51a32', marginLeft: 4 }}>{errors.method}</h6> : null}
          </div> */}

          <div className="walletBankSection">
            <div
              className={
                I18n.languageType == "ur"
                  ? "companyLogoMain companyLogoMainUrdu"
                  : "companyLogoMain"
              }
            >
              <div
                className={
                  I18n.languageType == "ur"
                    ? "radioLablelBox radioLablelBoxUrdu"
                    : "radioLablelBox"
                }
              >
                <label>
                  <input
                    className="option-input radio"
                    type="radio"
                    checked={method === "EasyPaisa"}
                    onClick={() => handleMethod("EasyPaisa")}
                    name=""
                  />
                  EasyPaisa
                </label>
              </div>
              <div
                className={
                  I18n.languageType == "ur"
                    ? "radioLablelBox radioLablelBoxUrdu"
                    : "radioLablelBox"
                }
              >
                <label>
                  <input
                    className="option-input radio"
                    type="radio"
                    checked={method === "JazzCash"}
                    onClick={() => handleMethod("JazzCash")}
                    name=""
                  />
                  JazzCash
                </label>
              </div>
              {
                alfalah ? (
                  <div
                    className={
                      alfalah
                        ? method == "Alfalah"
                          ? "radioLablelBox"
                          : "radioLablelBox"
                        : "radioLablelBox radioDisable"
                    }
                  >
                    <label>
                      <input
                        className="option-input radio"
                        type="radio"
                        checked={method === "Alfalah"}
                        onClick={() => handleMethod("Alfalah")}
                        disabled={
                          alfalah ? (method == "Alfalah" ? false : false) : true
                        }
                        name=""
                      />
                      Card
                    </label>
                  </div>
                ) : null
                // <div className="radioLablelBox">
                //   <label>
                //     <input
                //       className="option-input radio"
                //       type="radio"
                //       checked={method === "BankTransfer"}
                //       onClick={() => handleMethod("BankTransfer")}
                //       name=""
                //     />
                //     Bank
                //   </label>
                // </div>
              }
            </div>

            {cashbackPromotion ? (
              <div className="companyLogoMain">
                <div className="cashBackPara">
                  {method == "EasyPaisa" ? (
                    <p className="teleCashbook">
                      {cashbackPercentage}% Cashback
                    </p>
                  ) : method == "JazzCash" ? (
                    <p className="jazzCashbook">
                      {cashbackPercentage}% Cashback
                    </p>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          {method === "Alfalah" ? null : (
            <h6 className="paymentMethodSubHeading">
              {I18n.account_information}
            </h6>
          )}

          <form onSubmit={(e) => handleNext}>
            {method != "Alfalah" ? (
              <div className="inputBoxMain">
                <div
                  className={`inputBoxinput accountInfoinput ${
                    I18n.languageType == "ur" ? "accountInfoinputUrdu" : ""
                  }`}
                >
                  <div className="accountInfoInputBoxCon">
                    <input
                      ref={amountRef}
                      value={accountNumber}
                      maxLength={method == "BankTransfer" ? 18 : 11}
                      inputmode="numeric"
                      className="form-control"
                      onChange={(e) => handleChange(e.target.value)}
                      placeholder={
                        I18n.languageType == "ur"
                          ? `${I18n.number} ${I18n.enter} ${method}`
                          : `${I18n.enter} ${method} ${I18n.number}`
                      }
                      pattern="[0-9]*"
                    />
                    <span
                      className={
                        I18n.languageType == "ur"
                          ? "accountInfoMethodIcon accountInfoMethodIconUrdu"
                          : "accountInfoMethodIcon"
                      }
                    >
                      {method == "EasyPaisa" ? (
                        <img src={EasyPaisa} title="" alt="" />
                      ) : method == "JazzCash" ? (
                        <img
                          className={I18n.languageType == "ur" ? "JcIcon" : ""}
                          src={JazzCash}
                          title=""
                          alt=""
                        />
                      ) : method == "Alfalah" ? (
                        <img src={BAFL} title="" alt="" />
                      ) : // method == "BankTransfer" ? (
                      //   <img
                      //     className="bankTransferIcon"
                      //     src={BTransfer}
                      //     title=""
                      //     alt=""
                      //   />
                      // ) :
                      null}
                    </span>
                  </div>
                  {errors.accountNumber ? (
                    <p className="inputError">{errors.accountNumber}</p>
                  ) : (
                    <p className="inputError"></p>
                  )}
                </div>
              </div>
            ) : null}

            <h6
              className={
                I18n.languageType == "ur"
                  ? "paymentMethodSubHeading paymentMethodSubHeadingUrdu"
                  : "paymentMethodSubHeading"
              }
            >
              {I18n.country}
            </h6>
            <div className="inputBoxMain">
              <div
                className={`inputBoxinput accountInfoinput ${
                  I18n.languageType == "ur" ? "accountInfoinputUrdu" : ""
                }`}
              >
                <div className="accountInfoInputBoxCon">
                  <input
                    value={I18n.pakistan}
                    className="form-control"
                    onChange={(e) => {}}
                    placeholder={"Enter Country or Region"}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="inputBoxbutton">
              {method == "Alfalah" && alfalahStatus != false ? (
                alfalahStatus != false && (
                  <PaymentForm alfalahStatus={alfalahStatus} />
                )
              ) : (
                <button
                  type="submit"
                  className={
                    method == "Alfalah" ||
                    (method && accountNumber.length == 11)
                      ? "nextButton"
                      : "nextButton nextButtondisable"
                  }
                  onClick={handleNext}
                  disabled={otpRequestLoad}
                >
                  {otpRequestLoad ? (
                    <Spinner
                      className="loaderCircle nextButtonLoader"
                      animation="border"
                      role="status"
                    ></Spinner>
                  ) : method == "Alfalah" && alfalahStatus != false ? (
                    "Proceed To Pay"
                  ) : (
                    "Next"
                  )}
                </button>
              )}
            </div>

            <p
              dir="auto"
              className={
                I18n.languageType == "ur"
                  ? "confirmingPara confirmingParaUrdu"
                  : "confirmingPara"
              }
            >
              {I18n.by_confirmuing_your_payment_you_allow}{" "}
              <span>{business_name}</span> {I18n.to_charge_for_this}
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
