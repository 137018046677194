import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "reactstrap";
import {
  alfalahHandshake,
  getPaymentInformation,
  handleBank,
  makePayment,
  modifyVerifiedSession,
  verifyPayment,
} from "../../../SyncServices";
import "./style.css";
import MPin from "./MPin";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../constants";
import OtpInput from "react-otp-input";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import PaymentForm from "../PaymentForm";
import EasyPaisa from "../../../assets/images/easyPaisaIcon.png";
import JazzCash from "../../../assets/images/JC.png";
import BAFL from "../../../assets/images/BAFL.png";
import BTransfer from "../../../assets/images/bankIcon.png";

const DURATION = 59;
const MPIN_DURATION = 120;

const AccountInfo = ({
  history,
  amountRef,
  method,
  payable,
  unique_code,
  business_name,
}) => {
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [seconds, setSeconds] = useState(DURATION);
  const [mpinSeconds, setMpinSeconds] = useState(MPIN_DURATION);
  const [mpinStart, setMpinStart] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [doc_id, setDocId] = useState("");
  const [isOtp, setIsOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [code, setCode] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const [showResendCode, setShowResendCode] = useState(false);
  const [alfalahStatus, setAlfalahStatus] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);

  const toggle = () => setIsWaiting((prev) => !prev);

  useEffect(() => {
    logEvent(analytics, "account_info_viewed");
    if (unique_code == undefined) {
      const u_code = localStorage.getItem("unique_code");
      history.replace("/" + u_code);
    }
  }, []);

  useEffect(() => {
    if (startTimer == true) {
      if (seconds > 0) {
        setTimeout(() => {
          setSeconds(seconds - 1);
        }, 1000);
      } else if (seconds == 0) {
        setShowResendCode(true);
        setStartTimer(false);
      }
    }
  }, [seconds, startTimer]);

  useEffect(() => {
    if (mpinStart == true) {
      if (mpinSeconds > 0) {
        setTimeout(() => {
          setMpinSeconds(mpinSeconds - 1);
        }, 1000);
      } else if (mpinSeconds == 0) {
        alert("MPIN request timed out!");
        window.open("/" + unique_code, "_self");
        setMpinStart(false);
      }
    } else setMpinSeconds(MPIN_DURATION);
  }, [mpinSeconds, mpinStart]);

  const handleChange = (text) => {
    let newText = "";
    for (var i = 0; i < text.length; i++) {
      if (!isNaN(text[i]) && text[i] !== " ") {
        newText = newText + text[i];
        if (newText.length == 11) logEvent(analytics, "account_number_entered");
      }
    }

    setNumber(newText);

    // if (/^\d*\.?\d*$/.test(text)) {
    //   if (text.length == 11) logEvent(analytics, "account_number_entered");
    //   setNumber(+text);
    // }
  };

  const handleOtp = (otp) => {
    setCode(otp);
    setError("");
    if (otp.length == 4) {
      logEvent(analytics, "otp_entered");
      verifyDeposit(otp);
    }
  };

  const handleNext = (e) => {
    if (method === "Alfalah") {
      if (alfalahStatus != false) redirectToPayment();
      else handshakeBank();
    } else {
      e.preventDefault();
      setLoading(true);
      setShowResendCode(false);
      setIsCaptcha(false);
      setIsOtp(false);
      setShowOtp(false);
      setShowCaptcha(false);
      setLoading(true);
      setStartTimer(false);
      setError("");
      setSeconds(DURATION);
      setInvalidCode(false);
      setIsWaiting(false);
      setMpinSeconds(MPIN_DURATION);
      setMpinStart(false);

      let patt = /03[0-9]{2}(?!1234567)(?!1111111)(?!7654321)[0-9]{7}/; // VALIDATION FOR PAKISTANI MOBILE NUMBER

      if (number.match(patt)) {
        verifyPayment(unique_code, number, method)
          .then((res) => {
            logEvent(analytics, "verify_payment", {
              method,
            });
            if (res.verified == true) {
              proceedToPay(res.document_id);
            } else if (res.captcha == true) {
              logEvent(analytics, "captcha_verification", {
                method,
              });
              setIsCaptcha(true);
              setShowCaptcha(true);
              setLoading(false);
            } else if (res.otp_sent == true) {
              setCode("");
              setIsOtp(true);
              setShowOtp(true);
              setStartTimer(true);
              setLoading(false);
            }
            setDocId(res.document_id);
          })
          .catch((err) => {
            setStartTimer(false);
            setError(err.data?.message);
            setSeconds(DURATION);
            setLoading(false);
          });
      } else {
        setLoading(false);
        logEvent(analytics, "incorrect_phone_format");
        // alert(
        //   "Please enter the phone number in the correct format (e.g. 03001111111)"
        // );
        setErrorNumber(
          "Please enter the phone number in the correct format (e.g. 03001111111)"
        );
      }
    }
  };

  const verifyDeposit = (val) => {
    setLoading(true);
    let PARAMS = {
      payment_process: "online_payment",
      document_id: doc_id,
      unique_code,
    };
    if (isCaptcha == true) PARAMS["g-recaptcha-response"] = val;
    else if (isOtp == true) PARAMS["otp"] = val;
    setCode("");
    modifyVerifiedSession(PARAMS)
      .then((res) => {
        if (res.verified == true) {
          setShowOtp(false);
          setShowCaptcha(false);
          proceedToPay();
        } else if (res.otp_sent == true) {
          setSeconds(DURATION);
          setStartTimer(true);
          setIsCaptcha(false);
          setIsOtp(true);
          setShowOtp(true);
        }
        logEvent(
          analytics,
          res.verified == true
            ? "payment_verified"
            : res.otp_sent == true && "otp_sent"
        );
      })
      .catch((err) => {
        logEvent(
          analytics,
          isCaptcha == true ? "captcha_failed" : isOtp == true && "otp_failed",
          {
            error: err.data?.message || "unrecognised_error",
          }
        );
        if (showOtp != true) setShowOtp(false);
        else {
          setCode("");
          setInvalidCode(true);
          setShowResendCode(true);
        }
        setStartTimer(false);
        setSeconds(DURATION);
        setLoading(false);
        setError(err.data?.message);
      });
  };

  const proceedToPay = (docId = "") => {
    makePayment(unique_code, number, method, docId || doc_id)
      .then((res) => {
        checkIfPaid();
      })
      .catch((err) => {
        logEvent(analytics, "payment_failed", {
          method,
          error: err.data?.message || "unrecognised_error",
        });
        setIsOtp(false);
        setStartTimer(false);
        setError("");
        setSeconds(DURATION);
        setLoading(false);
        setError(err.data?.message);
        console.log("ERROR!");
      });
  };

  const navigateToEPInstructions = () => {
    logEvent(analytics, "easypaisa_instructions_viewed");
    history.push("/easypaisa_instructions");
  };

  const onChange = (value) => {
    logEvent(analytics, "captcha_provided");
    setShowCaptcha(false);
    verifyDeposit(value);
  };

  const checkIfPaid = () => {
    setLoading(true);
    toggle();
    setMpinStart(true);
    let interval = setInterval(() => {
      getPaymentInformation(unique_code)
        .then((res) => {
          const { payment_status, message } = res;
          if (payment_status === "paid") {
            toggle();
            setLoading(false);
            clearInterval(interval);
            logEvent(analytics, "payment_successful", {
              method,
            });
            setLoading(false);
            console.log("RES", res);
            history.push({
              pathname: "/success_screen",
              payable,
              number,
              business_name,
              unique_code,
            });
          } else if (payment_status == "failed") {
            toggle();
            clearInterval(interval);
            setLoading(false);
            setMpinStart(false);
            alert(message);
          }
        })
        .catch(() => {
          toggle();
          alert("Unexpected error occured");
          setLoading(false);
          clearInterval(interval);
        });
    }, 5000);
  };

  const handshakeBank = () => {
    setLoading(true);
    alfalahHandshake({ unique_code })
      .then((res) => {
        console.log(res);
        if (res.success == false) alert(res.message);
        else setAlfalahStatus(res.step_2_request_body);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const redirectToPayment = () => {
    setLoading(true);
    handleBank(alfalahStatus)
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <MPin
        modal={isWaiting}
        toggle={toggle}
        method={method}
        payable={payable}
        number={number}
        business_name={business_name}
        unique_code={unique_code}
        error={error}
        setIsWaiting={setIsWaiting}
        setError={setError}
        seconds={mpinSeconds}
        DURATION={MPIN_DURATION}
        setSeconds={setMpinSeconds}
        setStartTimer={setMpinStart}
        handleNext={() => handleNext()}
      />
      <div className="inputBoxMainCont">
        {/* <Header business_name={business_name} /> */}
        <form onSubmit={(e) => handleNext}>
          {method != "Alfalah" ? (
            <div className="inputBoxMain">
              <div className="inputBoxinput accountInfoinput">
                <div className="accountInfoInputBoxCon">
                  <input
                    ref={amountRef}
                    value={number}
                    maxLength={method == "BankTransfer" ? 18 : 11}
                    inputmode="numeric"
                    className="form-control"
                    onChange={(e) => handleChange(e.target.value)}
                    placeholder={"Enter " + method + " number"}
                    pattern="[0-9]*"
                  />
                  <span className="accountInfoMethodIcon">
                    {method == "EasyPaisa" ? (
                      <img src={EasyPaisa} title="" alt="" />
                    ) : method == "JazzCash" ? (
                      <img src={JazzCash} title="" alt="" />
                    ) : method == "Alfalah" ? (
                      <img src={BAFL} title="" alt="" />
                    ) : method == "BankTransfer" ? (
                      <img
                        className="bankTransferIcon"
                        src={BTransfer}
                        title=""
                        alt=""
                      />
                    ) : null}
                  </span>
                </div>
                {errorNumber ? (
                  <p className="inputError">{errorNumber}</p>
                ) : (
                  <p className="inputError"></p>
                )}
              </div>
            </div>
          ) : (
            alfalahStatus != false && (
              <PaymentForm alfalahStatus={alfalahStatus} />
            )
          )}
          <p className="errorMsg">{isOtp == false && error ? error : ""}</p>

          <h6 className="paymentMethodSubHeading">Country or Region</h6>
          <div className="inputBoxMain">
            <div className="inputBoxinput accountInfoinput">
              <div className="accountInfoInputBoxCon">
                <input
                  value="Pakistan"
                  className="form-control"
                  onChange={(e) => {}}
                  placeholder={"Enter Country or Region"}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="inputBoxbutton">
            {method == "Alfalah" && alfalahStatus != false ? null : (
              <button
                type="submit"
                className={
                  method == "Alfalah" || number.length == 11
                    ? "nextButton"
                    : "nextButton nextButtondisable"
                }
                onClick={handleNext}
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    className="loaderCircle nextButtonLoader"
                    animation="border"
                    role="status"
                  ></Spinner>
                ) : method == "Alfalah" && alfalahStatus != false ? (
                  "Proceed To Pay"
                ) : (
                  "Next"
                )}
              </button>
            )}
          </div>

          <p className="confirmingPara">
            By confirming your payment, you allow <span>{business_name}</span>{" "}
            to charge for this payment.
          </p>
        </form>
      </div>
      <Modal isOpen={showCaptcha} backdrop="static" centered>
        <ReCAPTCHA
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={onChange}
          badge="inline"
        />
      </Modal>

      <Modal isOpen={showOtp} backdrop="static" centered>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="backIconMain">
              <span onClick={() => history.goBack()}>
                <i className="backIcon"></i>
              </span>
            </div>
            <div className="col-sm-3">
              <div className="agentImage">
                <span className="avatar_img med"></span>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="login_page_top_content">
                <h4>
                  Verify <b>{number}</b>
                </h4>
                <p>Apko Code SMS Kar Diya Hai</p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-12">
              <div className="verificationInputMainCont">
                <p className="enterDigitTex">4 Digit Code Enter Karein</p>
                <div className="verificationInputInnerMain">
                  <OtpInput
                    shouldAutoFocus={true}
                    containerStyle={{ width: "100%" }}
                    className="verificationInputInnerCont"
                    value={code}
                    onChange={handleOtp}
                    numInputs={4}
                    isInputNum={true}
                  />
                </div>
                {invalidCode ? (
                  <p className="errorMsg">
                    {isOtp == true && error ? error : ""}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
              {showResendCode == false ? (
                <div className="waitingForCodeNumber">
                  <p className="waitingCodeTex">
                    {`Waiting for OTP code: 00:${
                      seconds > 9 ? seconds : "0" + seconds
                    }`}
                  </p>
                </div>
              ) : (
                <div className="waitingForCode">
                  <p className="waitingCodeTex">
                    Kia Code Nahi Mila? Abhi Resend Code Karein
                  </p>
                </div>
              )}
              {showResendCode == true && (
                <>
                  <div className="verificationActionSectiion">
                    <button
                      type="submit"
                      className={"verification_btn"}
                      onClick={handleNext}
                      // onClick={() => setNetworkModal(!networkModal)}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          alt="Toko Logo"
                        >
                          <path
                            fill="#3D0137"
                            d="M16.833,3.198H3.677c-1.161,0-2.104,0.943-2.104,2.104v8.161C1.572,14.021,1.8,14.566,2.198,14.96
                                        c0.395,0.394,0.922,0.608,1.499,0.608l0,0l10.491,0.01c0.156,0,0.309,0.048,0.434,0.137l2.475,1.792
                                        c0.199,0.146,0.436,0.224,0.68,0.224l0,0c0.373,0,0.725-0.182,0.941-0.48c0.143-0.197,0.223-0.439,0.223-0.684V5.303
                                        C18.938,4.142,17.992,3.198,16.833,3.198z M2.953,5.303c0-0.399,0.325-0.724,0.724-0.724h13.156c0.399,0,0.726,0.325,0.726,0.724
                                        v10.844l-2.142-1.545c-0.36-0.262-0.787-0.399-1.24-0.399L3.688,14.189H3.681c-0.194,0-0.376-0.074-0.513-0.211
                                        c-0.136-0.136-0.215-0.322-0.215-0.516V5.303z M6.404,7.965c0.802,0,1.452,0.65,1.452,1.452s-0.65,1.452-1.452,1.452
                                        c-0.802,0-1.452-0.65-1.452-1.452S5.602,7.965,6.404,7.965z M10.251,7.965c0.802,0,1.452,0.65,1.452,1.452s-0.65,1.452-1.452,1.452
                                        s-1.452-0.65-1.452-1.452S9.449,7.965,10.251,7.965z M14.09,10.869c0.73,0,1.337-0.543,1.438-1.246l0.033-0.003l-0.002-0.221
                                        c-0.012-0.791-0.662-1.435-1.469-1.435c-0.8,0-1.451,0.652-1.451,1.452C12.639,10.218,13.29,10.869,14.09,10.869z"
                          />
                        </svg>
                      </span>
                      <span>Resend Code</span>
                    </button>
                  </div>
                  <div className="callBtnPayment">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 51 51"
                        alt="Toko Logo"
                      >
                        <path
                          fill="#3D0137"
                          d="M32.83,40.029l-0.038-0.002c-2.313-0.143-4.37-1.029-5.716-1.688c-3.9-1.896-7.312-4.568-10.138-7.938
                                        c-2.344-2.826-3.916-5.455-4.951-8.281c-0.458-1.308-0.962-3.022-0.83-4.821c0.101-1.174,0.577-2.187,1.418-3.029l2.213-2.213
                                        c0.695-0.658,1.466-0.994,2.248-0.994c0.555,0,1.392,0.174,2.225,1.002c0.379,0.347,0.794,0.762,1.139,1.138
                                        c0.204,0.242,0.408,0.446,0.608,0.647l1.768,1.8c0.853,0.851,1.033,1.697,1.033,2.254c0,0.782-0.368,1.584-1.037,2.259l-0.571,0.57
                                        c-0.466,0.469-0.971,0.97-1.473,1.441c0.368,0.858,0.905,1.707,1.739,2.748c1.678,2.062,3.415,3.642,5.307,4.825l0.051,0.027
                                        c0.02,0.008,0.111,0.059,0.199,0.109c0.102,0.059,0.223,0.125,0.352,0.184l0.093,0.049c0.065,0.037,0.134,0.07,0.202,0.105
                                        c0.078,0.037,0.16,0.079,0.24,0.127l2.035-2.035c1.338-1.336,3.205-1.321,4.479,0.02l3.549,3.552
                                        c1.326,1.321,1.313,3.142-0.033,4.526c-0.287,0.322-0.554,0.578-0.832,0.793c-0.442,0.406-0.793,0.768-1.096,1.125l-0.074,0.083
                                        c-0.951,1.052-2.244,1.614-3.729,1.614L32.83,40.029z M17.038,13.321c-0.261,0-0.536,0.222-0.677,0.356l-2.189,2.188
                                        c-0.468,0.469-0.712,0.982-0.767,1.615c-0.101,1.37,0.324,2.794,0.706,3.881c0.938,2.563,2.388,4.981,4.559,7.598
                                        c2.617,3.12,5.779,5.596,9.395,7.354c1.168,0.57,2.932,1.338,4.832,1.463l0.309-0.002c0.859,0,1.536-0.289,2.074-0.885
                                        c0.375-0.445,0.789-0.869,1.312-1.35l0.097-0.083c0.207-0.16,0.383-0.333,0.604-0.581c0.648-0.668,0.407-1.078,0.089-1.395
                                        l-3.569-3.571c-0.162-0.173-0.381-0.351-0.617-0.351c-0.248,0-0.491,0.19-0.652,0.353l-3.281,3.28l-1.48-0.892
                                        c-0.03-0.019-0.064-0.034-0.096-0.051c-0.1-0.047-0.193-0.097-0.287-0.147c-0.17-0.082-0.334-0.17-0.492-0.259
                                        c-0.051-0.03-0.092-0.054-0.131-0.073l-0.156-0.086c-2.141-1.336-4.059-3.078-5.901-5.343c-0.982-1.227-1.62-2.245-2.073-3.312
                                        l-0.601-1.421l1.127-1.057c0.491-0.457,0.983-0.947,1.441-1.408l0.569-0.567c0.278-0.281,0.379-0.529,0.379-0.667
                                        c0-0.237-0.207-0.494-0.381-0.666l-1.768-1.805c-0.238-0.237-0.49-0.492-0.703-0.745c-0.268-0.293-0.635-0.659-1-0.994
                                        C17.442,13.437,17.229,13.321,17.038,13.321z"
                        />
                      </svg>
                    </span>
                    <span>03450012332</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountInfo;
