import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import UdhaarLogo from "../../../assets/images/udhaarlogo.png";
import "./style.css";
const HeaderVoucher = () => {
  return (
    <>
      <header className="VoucherHeaderFixed">
        <Container>
          <Row>
            <Col>
              <img
                className="UdhaarLogo"
                src={UdhaarLogo}
                alt="Udhaar Book Logo"
                title="Udhaar Book Logo"
                //   onClick={() => redirect()}
              />
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default HeaderVoucher;
