import { bank_logo, easypaisa_logo, easypaisa_white, ub_wallet_logo } from "../../../assets/images";

export const methodList = [
    {
        icon: easypaisa_logo,
        title: "EasyPaisa",
        value: 'easypaisa'
    },
    {
        icon: bank_logo,
        title: "Bank (IBFT via 1 Bill)",
        value: 'bank'
    },
    {
        icon: ub_wallet_logo,
        title: "UdhaarBook",
        value: 'ub'
    },
];