import { Col, Container, Row } from "react-bootstrap";
import Icon from "../../../assets/Icons";

const InnerHeader = ({ history, visible }) => {

    const goBack = () => history.goBack();

    return (
        <div className="innerHeaderMainbox">
            <Container>
                <Row>
                    <Col xs={6}>
                        <div className="innerHeaderLeft">
                            {visible ?
                                <p className="marBot0 cursorPointer" onClick={goBack}>
                                    <Icon
                                        data={{
                                            viewBox: "0 0 7 10",
                                            name: "backIcon",
                                            fill: "",
                                            height: "10",
                                            width: "7",
                                        }}
                                    />
                                </p>
                                : null}
                        </div>
                    </Col>
                    <Col xs={6}>
                        {/* <div className="innerHeaderRight">
                            <p className="marBot0 cursorPointer">
                                <Icon
                                    data={{
                                        viewBox: "0 0 18 17",
                                        name: "helpIcon",
                                        fill: "#414143",
                                        height: "17",
                                        width: "18",
                                    }}
                                />
                            </p>
                        </div> */}
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default InnerHeader;