import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import PrimaryButton from "../PrimaryButton";
import BankList from "../BankList";

const VideoModal = ({
  handleClose,
  show,
  banks,
  selectedBank,
  handleSelectBank,
}) => {
  const [searchText, setSearchText] = useState("");
  const [bankList, setBankList] = useState(banks || []);

  useEffect(() => {
    if(show){
      setSearchText('');
      handleSelectBank('')
      setBankList(banks)
    }
  }, [show]);

  const handleBankVideo = () => {
    if(selectedBank?.tutorial_link_1bill) window.open(`https://www.youtube.com/shorts/${selectedBank?.tutorial_link_1bill}`)
    else toast.error("Video not available")
  };

  const handleChange = (value) => {
    setSearchText(value);
    setBankList(banks?.filter(x => x?.display_name?.toLowerCase().includes(value?.toLowerCase())));
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      className="videoModal"
      onHide={handleClose}
      show={show}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Bank muntakhib karein
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="BankListBodyWrapper">        
          <div className="inputDivStyle">
            <input
              autoFocus
              value={searchText}
              className="inputStyle"
              placeholder={"Bank name"}
              contentEditable={false}
              onChange={(e) => handleChange(e.target.value)}
            />
            <span className="bar"></span>
            {searchText ? (
              <label className="labelCustomPosition">{"Bank name"}</label>
            ) : (
              <label>{"Bank name"}</label>
            )}
          </div>
          <div className="bankListWrapper">
            <BankList
              banks={bankList}
              selectedBank={selectedBank}
              handleSelectBank={handleSelectBank}
              handleBankVideo={handleBankVideo}
            />
          </div>
        </div>
      </Modal.Body>

      <div className="homeActionBox">
        <PrimaryButton
          data={{
            className: `width40 fontSize16 fontWeight600 textUppercase buttonStyle ${
              selectedBank
                ? "BtnGreenBg whiteColor"
                : "BtnwhiteBg lightGrayColor BtnDisable"
            }`,
            disabled: Boolean(!selectedBank),
            title: "SEE VIDEO",
            onClick: handleBankVideo,
          }}
        />
      </div>
    </Modal>
  );
};
export default VideoModal;
