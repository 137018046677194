import React from 'react';
import { BANK_URL } from '../../../constants';

const PaymentForm = ({ alfalahStatus }) => (
    <div className="cardFormMainBox">
        <form action={BANK_URL} id="PageRedirectionForm" method="post" novalidate="novalidate">
            <input id="AuthToken" name="AuthToken" type="hidden" value={alfalahStatus.AuthToken} />
            <input id="RequestHash" name="RequestHash" type="hidden" value={alfalahStatus.RequestHash} />
            <input id="ChannelId" name="ChannelId" type="hidden" value={alfalahStatus.ChannelId} />
            <input id="Currency" name="Currency" type="hidden" value={alfalahStatus.Currency} />
            {/* <input id="IsBIN" name="IsBIN" type="hidden" value={'0'} /> */}
            <input id="ReturnURL" name="ReturnURL" type="hidden" value={alfalahStatus.ReturnURL} />
            <input id="MerchantId" name="MerchantId" type="hidden" value={alfalahStatus.MerchantId} />
            <input id="StoreId" name="StoreId" type="hidden" value={alfalahStatus.StoreId} />
            <input id="MerchantHash" name="MerchantHash" type="hidden" value={alfalahStatus.MerchantHash} />
            <input id="MerchantUsername" name="MerchantUsername" type="hidden" value={alfalahStatus.MerchantUsername} />
            <input id="MerchantPassword" name="MerchantPassword" type="hidden" value={alfalahStatus.MerchantPassword} />
            <input id="TransactionTypeId" name="TransactionTypeId" type="hidden" value={alfalahStatus.TransactionTypeId} />


            <input autocomplete="off" id="TransactionReferenceNumber" name="TransactionReferenceNumber" placeholder="Order ID" type="hidden" value={alfalahStatus.TransactionReferenceNumber} />
            <input autocomplete="off" id="TransactionAmount" name="TransactionAmount" placeholder="Transaction Amount" type="hidden" value={alfalahStatus.TransactionAmount} />
            <button type="submit" class="nextButton" id="run">PAY ONLINE</button>

        </form>
    </div>
)

export default PaymentForm