import Icon from "../../../assets/Icons";
import { Spinner } from "reactstrap";
import React from 'react';

const PrimaryButton = ({ data, prefix, suffix }) => {
    const {
        className,
        disabled,
        loading,
        onClick,
        title,
        ...rest
    } = data || {};

    const handleAction = (e) => {
        e.stopPropagation();
        onClick && onClick();
    };

    return (
        <button
            disabled={disabled || loading}
            onClick={handleAction}
            className={className}
            {...rest}
        >
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {prefix ? <Icon data={prefix} /> : null}
                    <span className={prefix ? "marLeft10" : suffix ? "marRight5" : ""}>
                        {title}
                    </span>
                    {suffix ? <Icon data={suffix} /> : null}
                </>
            )}
        </button>
    );
};

export default PrimaryButton;