import {
  capitalizeFirstLetter,
  formatAMPM,
  formatNum,
  formateDate_dd_month_yy,
  path,
} from "../../../constants";
import { getPaymentDetails } from "../../../SyncServices";
import { img_nonIdeal } from "../../../assets/images";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AppFooter, InnerHeader } from "../../common";
import { Spinner } from "reactstrap";

const Receipt = ({ history }) => {
  const { phone_number } = history.location?.state || {};

  const [receiptObj, setReceiptObj] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (phone_number) {
      setReceiptObj(history.location.state);
      setLoading(false);
    } else getReceiptDetails();
  }, [phone_number]);

  const getReceiptDetails = () => {
    try {
      let params = {
        type: path[1] == "i" ? "invoice" : "salebook",
        business_report_url: path[2],
        invoice_id: path[3],
      };

      getPaymentDetails(params)
        .then((res) => setReceiptObj(res))
        .finally(() => setLoading(false));
    } catch {
      setLoading(false);
    }
  };

  return loading ? (
    <div className="LoadingScreen">
      <Spinner animation="border" className="loaderCircle" />
      <p>Loading</p>
    </div>
  ) : Object.values(receiptObj)?.length ? (
    <div
      className={`transactionListMainBox ${
        receiptObj?.items?.length > 0 && receiptObj?.items?.length <= 5
          ? "heightWithFiveItem"
          : ""
      }`}
    >
      <InnerHeader
        history={history}
        visible={
          !Boolean(receiptObj.status == "paid" || receiptObj.bill_type == "out")
        }
      />
      <section
        className={`transactionListInnerBox ${
          receiptObj?.items?.length ? "heightWithItem" : "heightWithoutItem"
        }`}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fontSize30 fontWeight600 darkGrayColor textCenter marTop20 marBot20">
                Rs. {formatNum(receiptObj.amount)}
              </h1>
              <p className="transactionListPara">
                <span className="fontSize12 fontWeight700 lightGrayTwoColor">
                  Date
                </span>
                <span className="fontSize14 fontWeight400 darkGrayColor">
                  {formateDate_dd_month_yy(receiptObj.date)},{" "}
                  {formatAMPM(receiptObj.date)}
                </span>
              </p>
              {receiptObj?.items?.length ? (
                <>
                  <h2 className="fontSize12 fontWeight700 lightGrayTwoColor marTop20 marBot5 padLefttoRight">
                    Items purchased
                  </h2>
                  <div className="transactionListTable">
                    <table>
                      <thead>
                        <th>
                          <p>Item</p>
                        </th>
                        <th>
                          <p>Qty</p>
                        </th>
                        <th>
                          <p>Discount</p>
                        </th>
                        <th>
                          <p>Price</p>
                        </th>
                      </thead>
                      {receiptObj.items.map((item, index) => {
                        const {
                          discount_method,
                          image_url,
                          discount,
                          quantity,
                          price,
                          name,
                        } = item || {};

                        let disc = discount
                          ? discount_method == "rs"
                            ? discount
                            : (discount / 100) * price
                          : 0;

                        return (
                          <tr key={index}>
                            <td>
                              <p className="transactionItemBox">
                                <span className="transactionItemImage">
                                  <img
                                    src={image_url || img_nonIdeal}
                                    title=""
                                    alt=""
                                  />
                                </span>
                                <span className="marLeft10">{name}</span>
                              </p>
                            </td>
                            <td>
                              <p>{quantity}</p>
                            </td>
                            <td>
                              <p>Rs. {formatNum(disc)}</p>
                            </td>
                            <td>
                              <p>Rs. {formatNum(price)}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </>
              ) : null}
              {receiptObj.discount_method ? (
                <p className="transactionListPara">
                  <span className="fontSize12 fontWeight700 lightGrayTwoColor">
                    Discount
                  </span>
                  <span className="fontSize14 fontWeight500 darkGrayColor">
                    Rs.{" "}
                    {formatNum(
                      receiptObj.discount_method == "rs"
                        ? receiptObj.discount
                        : (receiptObj.discount / 100) * receiptObj.amount
                    )}
                  </span>
                </p>
              ) : null}
              <p className="transactionListPara">
                <span className="fontSize12 fontWeight700 lightGrayTwoColor">
                  Bill from
                </span>
                <span className="fontSize14 fontWeight400 darkGrayColor">
                  {receiptObj.store_name}
                </span>
              </p>
              <p className="transactionListPara noBorder">
                <span className="fontSize18 fontWeight700 brightOrangeColor">
                  {capitalizeFirstLetter(receiptObj.status)}
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <AppFooter />
    </div>
  ) : null;
};

export default Receipt;
