export default {
  languageType: "ur",
  lang: "ur",
  bill_from: "کی طرف سے بل",
  pay_with_bank: "تک بینک ٹرانسفر کے ساتھ ادائیگی کریں",
  earn_bonus: " اور 1% بونس حاصل کریں",
  pay_with_bank_earn_bonus:
    " تک بینک ٹرانسفر کے ساتھ ادائیگی کریں اور 1% بونس حاصل کریں۔",
  bill_Date: "بل کی تاریخ",
  by_confirmuing_your_payment_you_allow: "اپنی ادائیگی کی تصدیق کر کے، آپ",
  to_charge_for_this: "کو ادائیگی کے لیے چارج کرنے کی اجازت دیتے ہیں۔",
  select_payment_method: "ادائیگی کا طریقہ منتخب کریں",
  account_information: "اکاؤنٹ کی معلومات",
  country: "رہائش",
  verify: "اپنا نمبر تصدیق کریں",
  apko_code_sms_kar_diya_hai: "آپ کو کوڈ ایس ایم ایس کر دیا گیا ہے",
  waiting_for_otp_code: "کوڈ کا انتظار کریں OTP",
  waiting_for_mpin_confirmation: " کی تصدیق کا انتظار کریں MPIN",
  apna: "اپنا",
  app_kholein_aur_pin_enter_karein: " آیپ کھولیں اور پن درج کریں",
  waiting_for_you_to_verify_easyload_deposot_amount:
    "آپ ایپ پر جا کر ٹرانسفر کی تصدیق کریں۔",
  app_needs_to_verify_your_easyload_amount:
    " ایپ پے اپنا ایزی لوڈ ڈیپوسٹ اماؤنٹ ویریفائی کریں",
  four_digit_code_eneter: "چار ڈیجٹ کوڈ درج کریں",
  kia_code_nhi_mila_abhi_resend_code:
    "کیا آپ کو ابھی تک کوڈ موصول نہیں ہوا؟ ری سینڈ کوڈ پہ کلک کریں",
  resend_code: "ری سینڈ کوڈ ",
  apne_mobile_networl_change: "Apne apna mobile network change kia hai?",
  apna_current_network_select_karein: "اپنا کرنٹ نیٹ ورک سلیکٹ کریں",
  please_try_again: "دوبارہ کوشش کریں",
  enter: "درج کریں",
  number: "نمبر",
  pakistan: "پاکستان",
  invalid_code: "غلط کوڈ",
  EasyPaisa: "Easypaisa",
  JazzCash: "Jazz Cash",
  months: [
    "جنوری",
    "فروری",
    "مارچ",
    "اپریل",
    "مئی",
    "جون",
    "جولائی",
    "اگست",
    "ستمبر",
    "اکتوبر",
    "نومبر",
    "دسمبر",
  ],
};
