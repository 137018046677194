import React from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../../common/Header";
import "./style.css";
import EasyPaisa from "../../../assets/images/EP.png";

const EasyPaisaInstructions = ({ location }) => {
  return (
    <div className={location?.cashbackPromotion ? "" : ""}>
      <Header />

      <section className="bodyContentpaymentmethod">
        <Container>
          <Row>
            <div className="BodyContentTop">
              <Col>
                <div className="totalHeading companyInstructions">
                  <p className="easypaisaLogo">
                    <img src={EasyPaisa} alt="" title="" />
                  </p>
                </div>

                <div className="contentHeading">
                  <h3>
                    Pay Via EasyPaisa <br />
                    in 5 Easy Steps and you are done
                  </h3>
                </div>

                <div className="instructionsList">
                  <ul>
                    <li>
                      <span>1</span>
                      <span>Open your EasyPaisa App</span>
                    </li>
                    <li>
                      <span>2</span>
                      <span>Select 'Bill Payment'</span>
                    </li>
                    <li>
                      <span>3</span>
                      <span>Select 'Udhaar'</span>
                    </li>
                    <li>
                      <span>4</span>
                      <span>Enter Udhaar App account number</span>
                    </li>
                    <li>
                      <span>5</span>
                      <span>Enter Details</span>
                      <ul>
                        <li>Account Number (Mobile Number)</li>
                        <li>Amount</li>
                        <li>Receiver Account Number (Mobile Number)</li>
                      </ul>
                    </li>
                    <li>
                      <span>
                        <i className=""></i>
                      </span>
                      <span>Done!</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default EasyPaisaInstructions;
