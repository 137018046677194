import EasyPaisaInstructions from "../components/container/EasyPaisaInstructions";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PaymentMethod from "../components/container/PaymentMethod";
import SuccessScreen from "../components/container/SuccessScreen";
import BankSuccess from "../components/container/BankSuccess";
import AccountInfo from "../components/container/AccountInfo";
import Receipt from "../components/container/Receipt";
import PayNow from "../components/container/PayNow";
import HomeV2 from "../components/container/HomeV2";
import { path, unique_code } from "../constants";
import Home from "../components/container/Home";
import React from "react";

const Routes = () => {

  return (
    <Router>
      <Switch>
        <Route path={"/easypaisa_instructions"} component={EasyPaisaInstructions} />
        <Route path={"/bafl/return"} exact component={BankSuccess} />
        <Route path={"/payment_method"} component={PaymentMethod} />
        <Route path={"/success_screen"} component={SuccessScreen} />
        <Route path={"/account_info"} component={AccountInfo} />
        <Route path={[`/i/${path[2]}/${path[3]}/paynow`, `/s/${path[2]}/${path[3]}/paynow`]} component={PayNow} />
        <Route path={[`/i/${path[2]}/${path[3]}/receipt`, `/s/${path[2]}/${path[3]}/receipt`]} component={Receipt} />
        <Route path={[`/i/${path[2]}/${path[3]}`, `/s/${path[2]}/${path[3]}`]} exact component={HomeV2} />
        <Route path={[`/${path[1]}`, `/${path[1]}/v2`, `/${unique_code}`, `/c/${unique_code}`]} exact component={Home} />
      </Switch>
    </Router>
  );
};

export default Routes;