import React, { useState } from "react";
import Icon from "../../../assets/Icons";

const BankList = ({
  banks,
  selectedBank,
  handleSelectBank,
  handleBankVideo,
}) => {
  return (
    <div className="detailScreenCardMain">
      {banks?.map((item, index) => {
        const { logo_link, display_name, tutorial_link_1bill } = item;
        return (
          <div
            className="detailScreenCardInner"
            key={index}
            onClick={() => handleSelectBank(item)}
          >
            <div className="detailScreenCard">
              <div className="detailScreenCardLeft">
                <img className="UdhaarLogo" src={logo_link} alt="" title="" />
              </div>

              <div className="detailScreenCardRight">
                <p>
                  <span className="fontSize14 fontWeight500 darkGrayColor">
                    {display_name}
                  </span>
                </p>

                {selectedBank?.display_name == display_name ? (
                  <Icon
                    data={{
                      viewBox: "0 0 12 9",
                      name: "checkIcon",
                      fill: "#4AC600",
                      height: "14",
                      width: "17",
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BankList;
