import { Col, Container, Row } from "react-bootstrap";
import { AppFooter, InnerHeader, PaySteps } from "../../common";
import React, { useLayoutEffect, useState } from "react";

const PayNow = ({ history }) => {
  const { consumer_number, payMethod, banks } = history.location || {};

  const [showBanks, setShowBanks] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");

  const toggleBanks = () => setShowBanks((prev) => !prev);

  const handleSelectBank = (bankDetail) => {
    setSelectedBank(bankDetail);
  };

  useLayoutEffect(() => {
    if (!payMethod) history.goBack();
  }, [payMethod]);

  return payMethod ? (
    <div className="">
      <InnerHeader history={history} visible={true} />
      <Container>
        <Row>
          <Col>
            <h1 className="fontSize18 fontWeight700 darkGrayColor marBot20 marTop20">
              {payMethod == "easypaisa" ? "Easypaisa" : "Bank"} se payment
              ba-asaani
            </h1>

            <PaySteps
              payMethod={payMethod}
              consumer_number={consumer_number}
              banks={banks}
              showBanks={showBanks}
              toggleBanks={toggleBanks}
              selectedBank={selectedBank}
              handleSelectBank={handleSelectBank}
            />
          </Col>
        </Row>
      </Container>
      <AppFooter />
    </div>
  ) : null;
};

export default PayNow;
