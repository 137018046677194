import React, { useEffect, useState } from 'react';
import { getCompleteInvoiceID, getInvoiceURL } from '../../../SyncServices';
import DownloadBanner from '../../common/DownloadBanner';
import Header from '../../common/Header';

const BankSuccess = ({ location, history }) => {
    const [params, setParams] = useState('')
    useEffect(() => {
        setParams({
            RC: getParams('RC'),
            RD: getParams('RD'),
            TS: getParams('TS'),
            O: getParams('O')
        })
    }, [])


    const getinvoiceurl = () => {
        let PARAMS = {
            invoice_id: params.O
        }
        getInvoiceURL(PARAMS).then(res => {
            window.open(res.invoice_url)
        }).catch(err => {
            alert("Please try again")
        })
    }

    const ReturnToPaymentScreen = () => {
        // let invoice = params.O
        let PARAMS = {
            invoice_id : params.O
        }
        getCompleteInvoiceID(PARAMS).then(res => {
            window.open(`/${res.invoice}`, '_self')
        }).catch(err => {
            alert("Please try again")
        })
        // if (invoice.length > 36) {
        //     invoice = invoice.split("-")
        //     invoice.pop()
        //     invoice = invoice.join("-")
        // }
        // console.log(invoice)

        // window.open(`/${invoice}`, '_self')
    }

    const getParams = (value) => {
        let search = location.search;
        let params = new URLSearchParams(search);
        return params.get(value);
    }

    return (
        <>
            <Header />
            <div className='paymentSucessScreenMain'>
                {params.TS == "P" ?
                    <>
                        <p className="succesScreentick">
                            <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 25 25' alt='Toko Logo'>
                                <g>
                                    <path fill="#4AC600" d="M12.611,24.117c-6.277,0-11.383-5.106-11.383-11.384c0-6.277,5.106-11.384,11.383-11.384
                            c6.278,0,11.384,5.106,11.384,11.384C23.995,19.011,18.889,24.117,12.611,24.117z M12.611,2.363
                            c-5.717,0-10.369,4.651-10.369,10.37s4.652,10.372,10.369,10.372c5.719,0,10.371-4.653,10.371-10.372S18.33,2.363,12.611,2.363z
                                "/>
                                </g>
                                <path fill="#4AC600" d="M17.727,9.697c-0.071-0.292-0.298-0.54-0.576-0.632c-0.327-0.107-0.625-0.024-0.907,0.26
                        c-0.896,0.897-1.791,1.794-2.688,2.691l-2.372,2.375l-1.003-1.004c-0.417-0.415-0.835-0.831-1.25-1.248
                        c-0.226-0.225-0.479-0.308-0.748-0.246c-0.34,0.078-0.573,0.294-0.673,0.626c-0.092,0.304-0.003,0.591,0.261,0.855l2.799,2.796
                        c0.181,0.181,0.397,0.276,0.626,0.276c0.229,0,0.448-0.097,0.631-0.28c0.243-0.243,0.486-0.487,0.727-0.729l1.503-1.513
                        c1.145-1.151,2.287-2.302,3.435-3.451C17.707,10.26,17.792,9.978,17.727,9.697z"/>
                            </svg>
                        </p>
                        <h4>Invoice paid successfully</h4>
                        <p>
                            <a className='payNowButton' onClick={getinvoiceurl}>
                                See paid invoice
                            </a>
                        </p>
                    </>
                    :
                    <>
                        <p className="succesScreentick">
                            <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 50 50' alt='Toko Logo'>
                                <path fill="#E51A32" d="M24.999,2.837c-12.25,0-22.215,9.966-22.215,22.216c0,12.251,9.965,22.219,22.215,22.219
                    s22.217-9.968,22.217-22.219C47.216,12.803,37.249,2.837,24.999,2.837z M33.892,33.865c-0.523,0.54-1.406,0.581-1.98,0.091
                    c-0.082-0.07-0.158-0.152-0.237-0.229c-2.132-2.134-4.268-4.268-6.399-6.403c-0.084-0.084-0.142-0.195-0.277-0.389
                    c-0.129,0.188-0.183,0.304-0.268,0.389c-2.146,2.152-4.299,4.299-6.444,6.453c-0.434,0.434-0.932,0.626-1.53,0.472
                    c-0.978-0.253-1.357-1.467-0.713-2.257c0.084-0.103,0.18-0.194,0.274-0.287c2.118-2.119,4.234-4.238,6.356-6.354
                    c0.094-0.092,0.223-0.147,0.336-0.219c0.004-0.061,0.012-0.12,0.016-0.181c-0.115-0.067-0.252-0.114-0.344-0.205
                    c-2.122-2.114-4.24-4.233-6.359-6.353c-0.092-0.094-0.188-0.186-0.271-0.287c-0.453-0.549-0.426-1.366,0.058-1.876
                    c0.489-0.518,1.325-0.586,1.894-0.147c0.123,0.094,0.23,0.206,0.34,0.314c2.118,2.118,4.238,4.236,6.355,6.355
                    c0.084,0.086,0.156,0.185,0.379,0.355c0.054-0.111,0.088-0.243,0.169-0.326c2.155-2.162,4.313-4.316,6.468-6.479
                    c0.338-0.339,0.721-0.542,1.204-0.506c0.577,0.043,1.007,0.328,1.218,0.873c0.217,0.563,0.122,1.092-0.303,1.528
                    c-0.662,0.679-1.337,1.342-2.008,2.012c-1.518,1.52-3.037,3.038-4.555,4.558c-0.084,0.084-0.165,0.172-0.34,0.355
                    c0.133,0.08,0.267,0.13,0.359,0.223c2.111,2.092,4.221,4.189,6.33,6.286c0.092,0.095,0.189,0.185,0.277,0.282
                    C34.407,32.476,34.403,33.337,33.892,33.865z"/>
                            </svg>
                        </p>
                        <h4>Transaction Failed</h4>
                        <p>
                            <a className='payNowButton' onClick={ReturnToPaymentScreen}>
                                Try Again
                            </a>
                        </p>
                    </>
                }
            </div>
            <DownloadBanner />
        </>
    )

}

export default BankSuccess