import React, { useState, createContext } from "react";
// import romn from "../languageConfig/local/romn";
// import snd from "../languageConfig/local/snd";
import en from "../languageConfig/local/en";
import ur from "../languageConfig/local/ur";

const localLang = localStorage.getItem("language");
let lang = {};
lang = en;

export const LanguageContext = createContext();

export const LanguageProvider = (props) => {
  const [I18n, changeLanguage] = useState(localLang === "ur" ? ur : en);

  return (
    <LanguageContext.Provider value={[I18n, changeLanguage]}>
      {props.children}
    </LanguageContext.Provider>
  );
};
