import { Container, Col, Row } from "react-bootstrap";
import { formatNum } from "../../../constants";
import { analytics } from "../../../firebase";
import { logEvent } from "firebase/analytics";
import Icon from "../../../assets/Icons";

const AmountCard = ({ data }) => {

    const { handleReceipt, amount, title, tel } = data || {};

    const handleCall = () => logEvent(analytics, "payment_link_call", {});

    return (
        <section className="paymentAmountCard">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="paymentAmountCardInner">

                            <h1 className="fontSize18 fontWeight700 OneLineTruncate darkGrayColor marBot0">
                                {title} requests

                            </h1>
                            <p className="fontSize56 fontWeight700 brightOrangeColor marTop10 marBot10">
                                Rs. {formatNum(amount)}

                            </p>
                            <ul className="paymentAmountAction">
                                <li>
                                    <a onClick={handleReceipt} className="darkGrayColor">
                                        <span className="marRight5">
                                            Receipt
                                        </span>
                                        <span>
                                            <Icon
                                                data={{
                                                    viewBox: "0 0 16 13",
                                                    name: "receiptIcon",
                                                    fill: "#565656",
                                                    height: "13",
                                                    width: "16",
                                                }}
                                            />
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href={tel} onClick={handleCall} className="darkGrayColor">
                                        <span className="marRight5">
                                            Call
                                        </span>
                                        <span>
                                            <Icon
                                                data={{
                                                    viewBox: "0 0 21 21",
                                                    name: "phoneIcon",
                                                    fill: "#565656",
                                                    height: "21",
                                                    width: "21",
                                                }}
                                            />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
};

export default AmountCard;