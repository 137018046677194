import AppRadioButton from "../AppRadioButton";
import { methodList } from "./methodList";
import React from "react";

const PayMethods = ({ handlePayMethod, payMethod }) => {

    return (
        <div className="optionListMainBox">
            {methodList.map((item, index) => {
                const { icon, value, title } = item;
                return (
                    <div className="optionListInnerBox" key={index}>
                        <AppRadioButton
                            checked={Boolean(payMethod == value)}
                            onChange={handlePayMethod}
                            value={value}
                            customValue={
                                <div className="optionListbody">
                                    <p className="fontSize14 marBot5 OneLineTruncate">
                                        <span className="fontWeight500 darkGrayColor">
                                            <img src={icon} alt={title} title={title} />
                                        </span>
                                        <span className="fontWeight400 darkGrayColor marLeft20">
                                            {title}
                                        </span>
                                    </p>
                                </div>
                            }
                        />
                    </div>
                )
            })}
        </div>
    );
};

export default PayMethods;