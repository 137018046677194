import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';
import { useEffect } from 'react';
import Routes from './Routes';
import React from 'react';
import './App.css';

function App() {

  useEffect(() => {
    window.addEventListener("load", onLoadEvent);
    return () => window.removeEventListener("load", onLoadEvent);
  }, []);

  const onLoadEvent = () => logEvent(analytics, `payment_link_opened`, {});

  return (
    <>
      <Routes />
      <ToastContainer
        position="bottom-left"
        closeOnClick={false}
        draggable={false}
        pauseOnFocusLoss
        autoClose={1000}
        hideProgressBar
        theme="dark"
        pauseOnHover
        newestOnTop
        rtl={true}
        limit={1}
      />
    </>
  );
}

export default App;
