import { Col, Container, Row } from "react-bootstrap";
import Icon from "../../../assets/Icons";

const AppFooter = () => {
    return (
        <div className="newFooter">
            <Container>
                <Row>
                    <Col>
                        <div className="newFooterInner">
                            <p className="marBot0 fontSize10 mediumGrayColor">
                                <span className="marRight5">
                                    <Icon
                                        data={{
                                            viewBox: "0 -1 11 13",
                                            name: "securedIcon",
                                            height: "12",
                                            width: "12",
                                        }}
                                    />
                                </span>
                                <span>
                                    Aapki payments 100% mehfooz hain
                                </span>
                                <span className="marRight5 marLeft5">
                                    <Icon
                                        data={{
                                            viewBox: "0 -1 8 10",
                                            name: "flashIcon",
                                            fill: "#F2BC1D",
                                            height: "10",
                                            width: "8",
                                        }}
                                    />
                                </span>
                                <span className="marRight5">
                                    by
                                </span>
                                <span className="ubLogo">
                                </span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AppFooter;