import React, { useState } from "react";
import HeaderVoucher from "../../common/headerVoucher";
import { Spinner, Container, Row, Col } from "reactstrap";
import netflixIcon from "../../../assets/images/netflixIcon.svg";
import ReCAPTCHA from "react-google-recaptcha";
import {

  RECAPTCHA_SITE_KEY,
} from "../../../constants";
const VoucherPhoneInput = ({
  handleNextFunc,
  phoneNumber,
  setPhoneNumber,
  setErrors,
  errors,
  otpRequestLoad,
  setMethod,
  method,
  voucherData,
}) => {
  const handleChange = (text) => {
    let newText = "";
    for (var i = 0; i < text.length; i++) {
      if (!isNaN(text[i]) && text[i] !== " ") {
        newText = newText + text[i];
      }
    }

    setPhoneNumber(newText);
  };

  const onChange = (value) => {
    console.log('recaptha ', value)
  };
  return (
    <div>
      <HeaderVoucher />

      <section className="vouchersSectionBodyBox">
        <div className="textSection">
          <Container>
            <Row>
              <Col>
                <p className="textStyle1">
                  Thank you for buying{" "}
                  <img src={voucherData?.icon} alt="" title="" /> voucher.
                </p>
                <p className="textStyle2">
                  To get your voucher code, enter your phone number
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <form onSubmit={(e) => handleNextFunc(e)}>
          <div className="inputSection">
            <Container>
              <Row>
                <Col>
                  <div className="inputDivStyle">
                    <input
                      autoFocus
                      required
                      maxLength={11}
                      value={phoneNumber}
                      className="inputStyle"
                      placeholder={""}
                      contentEditable={false}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                    <span className="bar"></span>
                    {phoneNumber ? (
                      <label className="labelCustomPosition">
                        {"Enter phone number"}
                      </label>
                    ) : (
                      <label>{"Enter phone number"}</label>
                    )}
                    {errors.phoneNumber ? (
                      <p className="inputErrorVoucher">{errors.phoneNumber}</p>
                    ) : (
                      <p className="inputErrorVoucher"></p>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div style={{

          }}>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={onChange}
              badge="inline"
            />
          </div>



          <div className="ButtonSection">
            <Container>
              <Row>
                <Col>
                  <div className="inputBoxbutton ButtonDivStyle">
                    <button
                      type="submit"
                      className={
                        method == "Alfalah" || phoneNumber.length == 11
                          ? "nextButton"
                          : "nextButton nextButtondisable"
                      }
                      onClick={handleNextFunc}
                      disabled={otpRequestLoad}
                    >
                      {otpRequestLoad ? (
                        <Spinner
                          className="loaderCircle nextButtonLoader"
                          animation="border"
                          role="status"
                        ></Spinner>
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </form>
      </section>
    </div>
  );
};

export default VoucherPhoneInput;
