import React from "react";
import { Container, Row, Col } from "reactstrap";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../../../constants";
import "./style.css";
import googleplay from "../../../assets/images/googleplay_black.svg";
import appstore from "../../../assets/images/appstore_black.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

const DownloadBanner = ({ disabled }) => {
  const goToUrl = (type) => {
    logEvent(analytics, "download_link_clicked", {
      store_type: type,
    });
    window.open(
      `${type == "google" ? GOOGLE_PLAY_LINK : APP_STORE_LINK}`,
      "_blank"
    );
    // const win = window.open(`${type == 'google' ? GOOGLE_PLAY_LINK : APP_STORE_LINK}`, '_self');
    // win.focus();
  };

  return (
    <>
      <div className="BodyContentBottom">
        <Container>
          <Row>
            <Col sm={12} md={{ size: 5, offset: 1 }} offset-md={1}>
              <div className="secondFooter">
                <p>
                  <span>Powered by</span>
                  <span>
                    <a href="https://udhaar.pk/" target="_blank">
                      <i className="udhaarIcon"></i>
                    </a>
                  </span>
                </p>
                <p className="tokoLabsPara">
                  © 2022 Toko Lab (Pvt) Ltd. All rights reserved
                </p>

                <div className="secondFooterBottomSection">
                  <ul>
                    <li>
                      <a href="https://udhaar.pk/terms-of-use/" target="_blank">
                        Terms
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://udhaar.pk/privacy-policy/"
                        target="_blank"
                      >
                        Privacy
                      </a>
                    </li>
                  </ul>
                  <p>
                    {/* <span>
                      <i className="contactUsIcon"></i>
                    </span> */}
                    <span>{"+92 345 0012332"}</span>
                  </p>
                </div>
              </div>
            </Col>
            {disabled ? null : (
              <Col sm={12} md={5}>
                <p className="trustedBusiness">
                  <span>
                    <i className="safesecurIcon"></i>
                  </span>

                  <span>Trusted by over 5.1M+ businesses</span>
                </p>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <footer className="dNone">
        <Container fluid={true}>
          <Row>
            <Col md="10" lg="10" sm="6" xs="6">
              <div className="footerLeftSide">
                <p className="install_app_right">
                  {"Get Udhaar Book for free."}
                </p>
              </div>
            </Col>
            <Col md="2" lg="2" sm="6" xs="6">
              <div className="footer_bottom_logo">
                <span>
                  <a target="_blank" onClick={() => goToUrl("google")}>
                    <img src={googleplay} />
                  </a>
                </span>
                <span>
                  <a target="_blank" onClick={() => goToUrl("apple")}>
                    <img src={appstore} />
                  </a>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default DownloadBanner;
