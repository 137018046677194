import axios from "axios";
import { BANK_URL, BASE_URL, REQ_URL } from "../constants"

export const getPaymentInformation = (unique_code) => {

  const URL = REQ_URL === 'v2' ? `${BASE_URL}/rnp/paymentrequest/` : `${BASE_URL}/rnp/v2/payment/`;

  return new Promise((resolve, reject) => {
    axios.get(URL,
      {
        params: {
          unique_code
        }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        console.log('getPaymentInformation catch', err);
        reject(err);
      })
  })
}

export const makePayment = (unique_code, customer_phone, telco_uid, document_id) => {

  const URL = REQ_URL === 'v2' ? `${BASE_URL}/rnp/paymentrequest/` : `${BASE_URL}/rnp/v2/payment/`;

  const PARAMS = {
    unique_code,
    customer_phone,
    telco_uid: (telco_uid).toLowerCase(),
    document_id
  }

  return new Promise((resolve, reject) => {
    axios.put(URL, PARAMS, {
      headers: {
        'App-Version': '30.8.7',
        'App-Platform': 'android'
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      console.log('makePayment catch', err.response);
      reject(err.response);
    })
  })
}

export const verifyPayment = (unique_code, customer_phone, telco_uid) => {
  const URL = `${BASE_URL}/rnp/captcha/`;
  const PARAMS = {
    payment_process: 'online_payment',
    unique_code,
    customer_phone,
    telco_uid: (telco_uid).toLowerCase(),
  }

  return new Promise((resolve, reject) => {
    axios.post(URL, PARAMS).then(res => {
      resolve(res.data);
    }).catch(err => {
      console.log('verifyPayment catch', err.response);
      reject(err.response);
    })
  })
}

export const modifyVerifiedSession = (PARAMS) => {
  const URL = `${BASE_URL}/rnp/captcha/`;

  return new Promise((resolve, reject) => {
    axios.put(URL, PARAMS).then(res => {
      resolve(res.data);
    }).catch(err => {
      console.log('modifyVerifiedSession catch', err.response);
      reject(err.response);
    })
  })
}

export const alfalahHandshake = (PARAMS) => {
  const URL = `${BASE_URL}/rnp/gateway/handshake/`;

  return new Promise((resolve, reject) => {
    axios.post(URL, PARAMS).then(res => {
      resolve(res.data);
    }).catch(err => {
      console.log('alfalahHandshake catch', err.response);
      reject(err.response);
    })
  })
}

export const handleBank = (PARAMS) => {
  return new Promise((resolve, reject) => {
    axios.post(BANK_URL, PARAMS).then(res => {
      resolve(res.data);
    }).catch(err => {
      console.log('handleBank catch', err.response);
      reject(err.response);
    })
  })
}


export const getInvoiceURL = (PARAMS) => {
  const URL = `${BASE_URL}/rnp/invoice/url/`;

  return new Promise((resolve, reject) => {
    axios.post(URL, PARAMS).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log("ERROR INVOICE URL FOR SUCCESSFULL PAYMENT", err.response)
      reject(err.response)
    })
  })
}

export const getCompleteInvoiceID = (PARAMS) => {
  const URL = `${BASE_URL}/rnp/invoice/url/`;

  return new Promise((resolve, reject) => {
    axios.get(URL, PARAMS).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log("ERROR INVOICE URL FOR SUCCESSFULL PAYMENT", err.response)
      reject(err.response)
    })
  })
}

export const getVoucherCode = (unique_code) => {
  const url = `${BASE_URL}/rnp/captcha/`;

  return new Promise((resolve, reject) => {
    axios
      .post(url, {

        unique_code,
        payment_process: 'voucher'


      })
      .then((res) => {
        // console.log("getVoucherCode res", res);
        resolve(res.data);
      })
      .catch((err) => {
        // console.log("getVoucherCode catch=====", err);
        reject(err);
      });
  });
};

export const getPaymentDetails = (params) => {
  return new Promise((resolve, reject) => {
    const url = `${BASE_URL}/udhaar/invoice/payment/`;

    axios
      .get(url, { params })
      .then((res) => {
        console.log("getPaymentDetails res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("getPaymentDetails err", err);
        reject(err);
      });
  });
};

export const getConsumerID = (params) => {
  return new Promise((resolve, reject) => {
    const url = `${BASE_URL}/udhaar/invoice/payment/`;

    axios.put(url, params)
      .then((res) => {
        console.log("getConsumerID res: ", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("getConsumerID err: ", err);
        reject(err);
      });
  });
};


export const putCallCaptcha = (document_id, unique_code, value) => {


  const url = `${BASE_URL}/rnp/captcha/`;
  return new Promise((resolve, reject) => {
    axios
      .put(url, {
        unique_code,
        document_id,
        payment_process: 'voucher',
        'g-recaptcha-response': value

      })
      .then((res) => {
        // console.log("putCallCaptcha res", res);
        resolve(res.data);
      })
      .catch((err) => {
        // console.log("putCallCaptcha catch", err.response);
        reject(err.response);
      });
  });
};



export const getAllData = (unique_code) => {
  const url = `${BASE_URL}/rnp/voucher/verify/`;
  // console.log('uniq code of muy', unique_code)
  return new Promise((resolve, reject) => {
    axios
      .get(url, {

        params: {
          unique_code
        }



      })
      .then((res) => {
        // console.log("getAllData res", res);
        resolve(res.data);
      })
      .catch((err) => {
        // console.log("getAllData catch=====", err);
        reject(err);
      });
  });
};
export const getPhoneInformation = (unique_code) => {


  const url = `${BASE_URL}/rnp/voucher/verify/`;
  // console.log("unique_code", unique_code);
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: {
          unique_code,
        },
      })
      .then((res) => {
        // console.log("getPhoneInformation res", res);
        resolve(res.data);
      })
      .catch((err) => {
        // console.log("getPhoneInformation catch", err.response);
        reject(err.response);
      });
  });
};

export const verifyNumber = (unique_code, phone_number) => {
  const URL = `${BASE_URL}/rnp/captcha/`;
  //   const URL = `192.0.21/rnp/voucher/verify/`;
  const PARAMS = {
    unique_code,
    phone_number,
    payment_process: "voucher",
  };

  console.log("params in phone input", PARAMS, URL);
  return new Promise((resolve, reject) => {
    axios
      .post(URL, PARAMS)
      .then((res) => {
        console.log("verifyNumber res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("verifyNumber catch", err.response);
        reject(err.response);
      });
  });
};

export const verifyOtpCode = (PARAMS) => {
  const URL = `${BASE_URL}/rnp/captcha/`;

  return new Promise((resolve, reject) => {
    axios
      .put(URL, PARAMS)
      .then((res) => {
        console.log("verifyOtpCode res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("verifyOtpCode catch", err.response);
        reject(err.response);
      });
  });
};
