import React, { useState, useEffect } from "react";
import HeaderVoucher from "../../common/headerVoucher";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import "./style.css";
import { getAllData, getVoucherCode } from "../../../SyncServices";
import { Container, Row, Col, Spinner, Modal } from "reactstrap";
const VoucherCodeScreen = ({
  documentID,
  setDocumentID,
  unique_code,
  phoneNumber,
}) => {
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setCopied(false);
  }, [1000]);

  useEffect(() => {
    getAllData(unique_code)
      .then((res) => {
        // console.log("getAllData ", res);
        setValue(res);
        setLoading(false);
      })
      .catch((e) => {
        // console.log("getAllData  catch", e);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div className="LoadingScreen">
          <Spinner animation="border" className="loaderCircle" />
          <p>Loading</p>
        </div>
      ) : (
        <div>
          <HeaderVoucher />

          <section className="vouchersSectionBodyBox">
            <div className="textSection">
              <Container>
                <Row>
                  <Col>
                    <p className="textStyle1">
                      Here is your <img src={value?.icon} alt="" title="" />{" "}
                      voucher code
                    </p>

                    {/* <p className="textStyle1">Apko code SMS kar diya hai</p> */}
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="inputSection">
              <Container>
                <Row>
                  <Col>
                    <div className="inputDivStyle">
                      <p className="voucherCodeTextStyle">Voucher Code</p>{" "}
                      <input
                        readOnly
                        value={value?.code}
                        maxLength={4}
                        className="inputStyle"
                        contentEditable={false}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="ButtonSection voucherCodeBtn">
              <Container>
                <Row>
                  <Col>
                    <div className="inputBoxbutton ButtonDivStyle">
                      <CopyToClipboard
                        text={value?.code}
                        onCopy={() => setCopied(true)}
                      >
                        <button className="nextButton">Copy</button>
                      </CopyToClipboard>
                      <br />
                      {copied ? (
                        <p className="VoucherCopyCode">Code copied</p>
                      ) : (
                        <p className="VoucherCopyCode"></p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {value?.redemption_url !== null ? (
              <a
                href={value?.redemption_url ? value?.redemption_url : ""}
                target="_blank"
                className="footerTextStyle"
              >
                Visit to redeem your code
              </a>
            ) : null}
          </section>
        </div>
      )}
    </>
  );
};

export default VoucherCodeScreen;
